<template>
  <div class="relative">
    <div class="relative">
      <div
        ref="scrollRef"
        class="relative w-full overflow-y-auto scroll-container"
        :class="{
          'mocked-full-width': isMobile && !disableMockedFullWidth,
          'scroll-smooth': scrollSmooth || !isMobile,
          'rounded-alt-xl md:overflow-hidden': rounded,
        }"
        @scroll="onScroll"
      >
        <div
          ref="wrapperRef"
          :class="[
            'flex flex-row flex-1 slider',
            {
              'wrapper ': isSlider,
              'staticSlider w-full': !isSlider,
              'justify-center': noSliderCenteredContent && !isSlider,
              noGap: noGap,
            },
          ]"
          @mouseover="onMouseEnter"
          @mouseleave="onMouseLeave"
        >
          <slot />
          <div
            v-if="isMobile && isSlider"
            class="flex-shrink-0 block padding-placeholder"
          />
        </div>
      </div>
      <div
        v-if="!hideButtons && !isTouch && isSlider"
        id="arrowLeft"
        class="absolute left-[-15px] w-[30px] h-[60px] rounded-r arrowpos flex items-center justify-center cursor-pointer button"
        @click="clickLeft"
      >
        <FaIcon icon-class="far fa-chevron-left" />
      </div>
      <div
        v-if="!hideButtons && !isTouch && isSlider"
        id="arrowRight"
        class="absolute right-[-15px] w-[30px] h-[60px] rounded-l arrowpos flex items-center justify-center cursor-pointer button"
        @click="clickRight"
      >
        <FaIcon icon-class="far fa-chevron-right" />
      </div>
    </div>
    <div
      v-if="!hideDots && dots && isSlider && !hasScrollBar"
      id="dots"
      class="flex items-center justify-center gap-[5px] py-[15px] flex-wrap"
      :class="[dotsOverlay ? '!absolute' : '', dotsBarClasses]"
    >
      <div
        v-for="i in dots"
        :key="i"
        class="block w-[9px] h-[9px] rounded-full shrink-0"
        :class="{
          dotActive: i - 1 === activeDot,
          dotInactive: i - 1 !== activeDot,
        }"
        @click="() => clickDot(i)"
      />
    </div>
    <template v-if="hasScrollBar">
      <scrollbar
        :scroll-pos="scrollPos"
        :scroll-container="scrollRef"
        @change-scroll-position="
          (value) => (scrollRef.scrollLeft = scrollRef.scrollWidth * value)
        "
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import Scrollbar from './components/scrollbar.vue';
import { mq_breakpointIsMobile, mq_isTouch } from '@/injectionSymbols';
import { useIsDesktopView } from '~~/src/composables/isDesktopView';
import FaIcon from '@/components/fa-icon.vue';
import { useFocusWithin } from '@vueuse/core';

const props = defineProps({
  showSlides: {
    type: Number,
    default: 4,
  },
  showSlidesTablet: {
    type: Number,
    default: undefined,
    required: false,
  },
  showSlidesMobile: {
    type: Number,
    default: 1,
    required: false,
  },
  dotsOverlay: {
    type: Boolean,
    default: false,
    required: false,
  },
  scrollSmooth: {
    type: Boolean,
    default: false,
    required: false,
  },
  hideButtons: {
    type: Boolean,
    default: false,
    required: false,
  },
  dotsBarClasses: {
    type: String,
    default: '',
    required: false,
  },
  autoPlay: {
    type: Boolean,
    default: false,
    required: false,
  },
  autoPlayInterval: {
    type: Number,
    default: 5000,
    required: false,
  },
  noGap: {
    type: Boolean,
    default: false,
    required: false,
  },
  disableMockedFullWidth: {
    type: Boolean,
    default: false,
    required: false,
  },
  noSliderCenteredContent: {
    type: Boolean,
    default: false,
    required: false,
  },
  rounded: {
    type: Boolean,
    default: false,
    required: false,
  },
  hideDots: {
    type: Boolean,
    default: false,
    required: false,
  },
});

const emit = defineEmits<{
  (e: 'slideChange', index: number): void;
}>();

const wrapperRef = ref<HTMLDivElement>(null);
const scrollRef = ref<HTMLDivElement>(null);

const isMobile = inject(mq_breakpointIsMobile);
const isDesktopView = useIsDesktopView();
const isTouch = inject(mq_isTouch);

const tabletSlides = props.showSlidesTablet ?? props.showSlides;

const slidesPerPage = computed(() =>
  isMobile.value
    ? props.showSlidesMobile
    : isDesktopView.value
      ? props.showSlides
      : tabletSlides,
);

const isSlider = computed(() => slidesNumber.value > slidesPerPage.value);

const slidesNumber = ref<number>(0);
useResizeObserver(wrapperRef, () => {
  // We have to subtract 1 from length because on mobile we add a padding placeholder div inside of wrapperLength
  slidesNumber.value =
    wrapperRef?.value?.children.length -
    (isMobile.value && isSlider.value ? 1 : 0);
});

const slideWidth = computed(
  () => `${100 / (isSlider.value ? slidesPerPage.value : slidesNumber.value)}%`,
);

const activeSlide = ref<number>(0);

const hasScrollBar = computed(() => isMobile.value && dots.value > 20);
const scrollPos = ref<number>(0);
const scrollPosReset = ref(true);
const scrollSmoothJs = ref(false);

// dot variables
const activeDot = ref<number>(0);
const dots = computed(() =>
  Math.ceil(slidesNumber.value / slidesPerPage.value),
);
function clickDot(dotIndex: number) {
  fullStopSlider();
  scrolltoSlide((dotIndex - 1) * slidesPerPage.value);
}

// scroll handling
function onScroll(e: Event) {
  const target = e.target as HTMLDivElement;
  // initial reset scrollposition
  if (scrollPosReset.value) {
    target.scrollLeft = 0;
    scrollSmoothJs.value = true;
    scrollPosReset.value = false;
    return;
  }
  const left = target.scrollLeft;
  // scrollbar
  scrollPos.value = (left / (target.scrollWidth - target.clientWidth)) * 100;
  // slide
  const el = wrapperRef.value?.children[1] as HTMLDivElement;
  if (el) {
    const offset = el.offsetLeft;
    activeSlide.value = Math.round(left / offset);
  }
  // dots
  const scrollContainer = scrollRef.value;
  if (scrollContainer) {
    const isScrollEnd =
      scrollContainer.clientWidth + scrollContainer.scrollLeft + 20 >
      scrollContainer.scrollWidth;
    activeDot.value = isScrollEnd
      ? dots.value - 1
      : Math.floor(activeSlide.value / slidesPerPage.value);
  }
}

function scrolltoSlide(index: number) {
  if (!wrapperRef.value || !scrollRef.value) return;
  scrollSmoothJs.value = true;
  scrollPosReset.value = false;

  const el = wrapperRef.value?.children[index] as HTMLDivElement;
  if (el) {
    scrollRef.value.scrollTo({ left: el.offsetLeft });
  } else {
    scrollRef.value.scrollTo({
      left: (
        wrapperRef.value?.children?.[
          wrapperRef.value?.children.length - 1
        ] as HTMLDivElement
      ).offsetLeft,
    });
  }

  activeSlide.value = index;
}

// arrow navigation
const fullStop = ref(false);

function fullStopSlider() {
  fullStop.value = true;
  stopAutoPlay();
}

function clickRight() {
  fullStopSlider();
  right();
}

function right() {
  const dynamic = Math.min(
    activeSlide.value + slidesPerPage.value,
    slidesNumber.value,
  );

  const constraint =
    Math.floor(dynamic / slidesPerPage.value) * slidesPerPage.value;
  scrolltoSlide(dynamic === slidesNumber.value ? 0 : constraint);
}

function clickLeft() {
  fullStopSlider();
  left();
}

function left() {
  const dynamic =
    activeSlide.value === 0
      ? slidesNumber.value
      : Math.max(activeSlide.value - slidesPerPage.value, 0);

  const constraint =
    Math.ceil(dynamic / slidesPerPage.value) * slidesPerPage.value;
  scrolltoSlide(constraint);
}

//event emit
watch(activeSlide, (next) => {
  emit('slideChange', next);
});

// auto play

let autoPlayIntervalObj: ReturnType<typeof setInterval>;

watch(
  [() => props.autoPlay, () => isSlider.value],
  (autoPlay, isSlider) => {
    if (autoPlay && isSlider) {
      startAutoPlay();
    } else {
      stopAutoPlay();
    }
  },
  { immediate: true },
);

function startAutoPlay() {
  if (
    props.autoPlay &&
    !autoPlayIntervalObj &&
    isSlider.value &&
    !fullStop.value
  ) {
    autoPlayIntervalObj = setInterval(right, props.autoPlayInterval);
  }
}
function stopAutoPlay() {
  if (autoPlayIntervalObj) {
    clearInterval(autoPlayIntervalObj);
    autoPlayIntervalObj = undefined;
  }
}

function onMouseEnter() {
  if (props.autoPlay) stopAutoPlay();
}

function onMouseLeave() {
  if (props.autoPlay && isSlider.value) startAutoPlay();
}

onMounted(() => {
  const { focused } = useFocusWithin(scrollRef);
  watch(focused, (v) => {
    if (v) stopAutoPlay();
    else startAutoPlay();
  });
});

onBeforeUnmount(() => {
  stopAutoPlay();
});
</script>
<style scoped lang="pcss">
.button {
  background: var(--silder-style-button-bg, var(--thm-btn-primary-base));
  color: var(--silder-style-button-color, white);
}

.button:hover {
  @apply bg-primary-base;
}

.dotActive {
  background: var(--silder-style-dot-active, var(--thm-primary-base));
}
.dotInactive {
  background: var(--silder-style-dot-inactive, var(--thm-border-light));
}

.wrapper:deep(> *:not(.padding-placeholder)) {
  flex-shrink: 0;
  height: auto;
}

.staticSlider:deep(> *:not(.padding-placeholder)) {
  height: auto;
}

.slider:not(.noGap) {
  @apply gap-sm;
  &:deep(> *:not(.padding-placeholder)) {
    width: calc(
      (v-bind(slideWidth) - theme('spacing.sm')) +
        (theme('spacing.sm') / v-bind(showSlidesMobile))
    );
    scroll-snap-align: center;
  }
}
.slider.noGap {
  &:deep(> *:not(.padding-placeholder)) {
    width: v-bind(slideWidth);
    scroll-snap-align: center;
  }
}

@media screen(desktop) {
  @media screen(md) {
    .slider:not(.noGap) {
      @apply gap-sm;
      &:deep(> *:not(.padding-placeholder)) {
        width: calc(
          (v-bind(slideWidth) - theme('spacing.sm')) +
            (theme('spacing.sm') / v-bind(tabletSlides))
        );
        scroll-snap-align: none;
      }
    }
    .slider.noGap {
      &:deep(> *:not(.padding-placeholder)) {
        width: v-bind(slideWidth);
        scroll-snap-align: none;
      }
    }
  }
}

@media screen(touch) {
  @media screen(md) {
    .slider:not(.noGap) {
      @apply gap-md;
      &:deep(> *:not(.padding-placeholder)) {
        width: calc(
          (v-bind(slideWidth) - theme('spacing.md')) +
            (theme('spacing.md') / v-bind($props.showSlides))
        );
      }
    }
    .slider.noGap {
      &:deep(> *:not(.padding-placeholder)) {
        width: v-bind(slideWidth);
      }
    }
  }
}

@media screen(lg) {
  .slider:not(.noGap) {
    @apply gap-md;
    &:deep(> *:not(.padding-placeholder)) {
      width: calc(
        (v-bind(slideWidth) - theme('spacing.md')) +
          (theme('spacing.md') / v-bind($props.showSlides))
      );
    }
  }
  .slider.noGap {
    &:deep(> *:not(.padding-placeholder)) {
      width: v-bind(slideWidth);
    }
  }
}

.child-size-show-n.gap-md:deep(> *:not(.padding-placeholder)) {
  width: calc(
    (v-bind(slideWidth) - theme('spacing.md')) +
      (theme('spacing.md') / v-bind(slidesPerPage))
  );
}

.child-size-show-1:deep(> *:not(.padding-placeholder)) {
  width: 100%;
  scroll-snap-align: center;
}

.padding-placeholder {
  width: calc(theme('spacing.sm') - theme('spacing.xs'));
  height: 10px;
}

.scroll-container::-webkit-scrollbar {
  display: none;
}

.scroll-container {
  -ms-overflow-style: none;
  scrollbar-width: none;

  scroll-snap-type: x proximity;
}

.mocked-full-width {
  margin: 0 calc(-1 * theme('spacing.sm'));
  padding: 0 theme('spacing.sm');
  width: calc(100% + (theme('spacing.sm') * 2));
}

:global(.styled-section .mocked-full-width) {
  padding: 0 !important;
  margin: 0 !important;
  width: 100% !important;
}

.arrowpos {
  top: calc(50% - 30px);
}
</style>
