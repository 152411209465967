<template>
  <div
    ref="scrollBarRef"
    class="my-[15px] relative scroll-bar block"
    :class="{ active: isScrolling }"
  >
    <div
      class="absolute block rounded-full scroll-bullet bg-vdv-base"
      :style="{ left: `${scrollPos}%` }"
      @mousedown.prevent="startDrag"
      @touchstart.prevent="startTouch"
    />
  </div>
</template>
<script setup lang="ts">
defineProps({
  scrollPos: {
    type: Number,
    default: 0,
  },
});
const emit = defineEmits<{
  (e: 'changeScrollPosition', value: number): void;
}>();

const scrollBarRef = ref<HTMLDivElement>(null);
const isScrolling = ref<boolean>(false);

// scroll bar handling
function handleMove(clientX: number) {
  const rect = scrollBarRef.value.getBoundingClientRect();
  const pos = (clientX - rect.x - 20) / rect.width;
  emit('changeScrollPosition', pos);
}

function mouseMove(e: MouseEvent) {
  handleMove(e.clientX);
}

function touchMove(e: TouchEvent) {
  handleMove(e.touches[0].clientX);
}

function startDrag() {
  isScrolling.value = true;
  document.addEventListener('mousemove', mouseMove);
  document.addEventListener(
    'mouseup',
    () => {
      document.removeEventListener('mousemove', mouseMove);
      isScrolling.value = false;
    },
    { once: true },
  );
}

function startTouch() {
  isScrolling.value = true;
  document.addEventListener('touchmove', touchMove);
  document.addEventListener(
    'touchend',
    () => {
      document.removeEventListener('touchmove', touchMove);
      isScrolling.value = false;
    },
    { once: true },
  );
}
</script>
<style scoped lang="postcss">
.scroll-bar {
  height: 9px;
  width: calc(100% - 9px);
  padding: 3px 0;

  &::before {
    content: '';
    display: block;
    border-radius: 5px;
    background: var(--scrollbar-style-bg, var(--thm-border-light));
    height: 3px;
    position: absolute;
    width: calc(100% + 9px);
  }
}
.scroll-bullet {
  background: var(--scrollbar-style-bullet-color, var(--thm-primary-base));
  width: 9px;
  height: 9px;
  top: 0px;
}
</style>
